
import React, { useEffect, useState } from "react";
import Main from "./pages/Main/Main";
import Login from "./components/Login/Login";
import Spinner from './components/Spinner/Spinner';
import Recovery from "./pages/Recovery/Recovery";
import { useDispatch, useSelector } from 'react-redux';
import { isLogIn } from './redux/slices/authSlice';
import md5 from "react-native-md5";
import { useHttp } from './hooks/http.hook';

import { Route, Routes } from "react-router-dom";

import "./scss/app.scss"
import { setCurrentAdmin } from "./redux/slices/adminSlice";


function App() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.auth);

  ///удалить при релизе
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { request, process, setProcess, test } = useHttp();

  const isLoading = () => {
    setLoading(true);
  }

  const isLoaded = () => {
    setLoading(false);
  }


  const login = (logName, pass) => {
    isLoading()
    //для локальной разработки
    if (test) {
      setTimeout(() => {
        window.sessionStorage.setItem('isLogin', true)
        window.sessionStorage.setItem('admin', JSON.stringify({name: 'john', login: 'logName'}))
        isLoaded()
      }, 300)
    } else {
      //для разработки на сервере

      request(undefined, {
        "command": "auth.authorization",
        "logName": logName,
        "password": md5.hex_md5(pass)
      })
        .then(res => {
          ///получаем ошибку
          if (res.status == "2") {
            //Вывод ошибки авторизации о некорректных данных
          }

          ///если все хорошо
          if (res.status == "1") {
            
            dispatch(isLogIn(true));
            window.sessionStorage.setItem('isLogin', true)
            window.sessionStorage.setItem('admin', JSON.stringify({name: res.message[0].name, login: logName}))
          }

          isLoaded()
        })
    }
  }

  let spinner;

  loading ? spinner = <Spinner active /> : spinner = <Spinner />

  if (!window.sessionStorage.getItem('isLogin')) {
  // if (!auth) {
    return (
      <>
        {spinner}
        <Routes >
          <Route path="/" element={<Login login={login} loginError={loadingError} />} />
          <Route path="/recovery/" element={<Recovery />} />
        </Routes>


      </>
    )
  } else {
    return (
      <>
        {spinner}
        <Main login={login}/>
      </>

    )
  }
}

export default App;
