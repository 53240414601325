import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DishForm from '../../components/DishForm/DishForm';
import PanelHeader from '../../components/panelHeader/PanelHeader';

function CreateDish() {

  const allergens = useSelector(state => state.matrix.allergens);

  const [dish, setDish] = useState({
    id: '',
    name: '',
    arabian: '',
    type: '0',
    kcal: '',
    carbs: '',
    fats: '',
    proteins: '',
    img1: '/',
    img2: '/',
    img3: '/',
    
    allergens: allergens.map( item => 0),
    ingredients: '',
    days: [0,0,0,0,0]
  }); // параметры блюда, 


  return (
    <>
      <PanelHeader title="Dish"/>
      <DishForm dish={dish} setDish={setDish} />
    </>
  )
}

export default CreateDish;