import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import PanelHeader from '../../components/panelHeader/PanelHeader';
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

import { useHttp } from "../../hooks/http.hook";
import './_parents.scss'
import trash from "./../../resourses/icons/trash.png";
const Parents = () => {

    const [loading, setLoading] = useState(false);
    const [parents, setParents] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [targetConfirm, setTargetConfirm] = useState({
        id: null,
        name: null,
        link: null
    });

    const { request, process, setProcess, test } = useHttp();



    // получаем список родителей
    const getParents = () => {
        if (test) {
            setParents([
                { id: 1, name: 'Name surname middle name', gender: 'male', email: 'email' },
                { id: 2, name: 'Name surname middle name', gender: 'male', email: 'email' },
                { id: 3, name: 'Name surname middle name', gender: 'male', email: 'email' }
            ])
        } else {
            setLoading(true);
            request(undefined, {
                "command": ""
            })
                .then(res => {
                    setLoading(false)
                    setProcess('confirmed');
                    setParents(res.data)
                })
        }
    }

    useEffect(() => {
        getParents();
    }, [])





    // отправляем запрос на удаление родителя
    const removeParent = (id) => {
        request(undefined, {
            "command": "",
            "id": id
        })
            .then(res => {
                setLoading(false)
                setProcess('confirmed');
                setShowConfirm(false);
                setParents(res.data)
            })
    }

    const onConfirmDelete = (id, name) => {
        setShowConfirm(true);
        setTargetConfirm({id, name})
    }

    const parentsList = parents.map(item => {
        const {id, name, gender, email} = item;
        return (
            <li key={id} className="rows-list__item">
            <div className='rows-list__box children__box'>
                <span>{id}</span>
                <span>{name}</span>
                <span>{gender}</span>
                <span>{email}</span>
               <Link className="rows-list__btn button" to={`/edit-parent/${id}`}>Edit</Link>
            </div>
            <button 
                className='btn-trash'
                onClick={() => onConfirmDelete(id, name)}
                >
                <img src={trash} alt="delete"/>
            </button>
        </li>
        )
    })
    return (
        <>
            {/* {spinner} */}
            <PanelHeader title="Parents" />
            <ul className="rows-list">
                {parentsList}
            </ul>
            <ConfirmModal question='Delete a child?' text1={targetConfirm.name} text2={targetConfirm.surname} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => removeParent(targetConfirm.id)}/>
        </>
    )
}

export default Parents;