import React, {useEffect, useState} from "react";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import Pagination from "../../components/Pagination/Pagination";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";

import "./_transaction.scss";
const Transaction = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null) // !!get this value from server side
  const [transactionList, setTransactionList] = useState([]);

  const {request, process, setProcess, test} = useHttp();




  
  useEffect(() => {

    if (test) {
      request(`https://6554c10063cafc694fe6e50f.mockapi.io/transaction?page=${currentPage}&limit=20`).then((res)=>{
        setTransactionList(res); //  Set the initial list of transactions to be displayed on
        setProcess('confirmed');
        setTotalPages(3)
      });

    } else {
      request(undefined, {
        command: "", // команда для получения списка транзакций от 20 шт
        pageNumber: currentPage
      }).then((res)=>{ 
        // ожидаю получить списрк транзакций и количество страниц с шагом в 20шт
        setTotalPages(res.totalPages)
        setTransactionList(res.message); //  Set the initial list of transactions to be displayed on
        setProcess('confirmed');
      });
    }

  }, []);

  const changePage = (step) => {
    if(test) {
      request(`https://6554c10063cafc694fe6e50f.mockapi.io/transaction?page=${currentPage + step}&limit=20`).then((res)=>{
        setTransactionList(res); //  Set the initial list of transactions to be displayed on
        setProcess('confirmed');
      });
    } else {
      request(undefined, {
        command: "", // команда для получения списка транзакций о 20 шт
        pageNumber: currentPage
      }).then((res)=>{
        setTotalPages(res.totalPages)
        setTransactionList(res.message); //  Set the initial list of transactions to be displayed on
        setCurrentPage(state => state + step);
        setProcess('confirmed');
      });
    }

  }

  //render table rows
  const renderItems = (arr) => {
    const tableRows = arr.map((item) => {
      const {id, transactionOutput, transactionID, userID, time, status, amount, tarif, promocode, discount} = item;
      return (
        <tr>
          <td>{id}</td>
          <td>{transactionOutput}</td>
          <td>{transactionID}</td>
          <td>{userID}</td>
          <td>{time.split('.')[0]}</td>
          <td>{status}</td>
          <td>{amount} SAR</td>
          <td>{tarif} </td>
          <td>{promocode}</td>
          <td>{discount}</td>
        </tr>
      )
    })
    return (
      <table className="table transactions">
      <tr>
        <th></th>
        <th>Transaction output</th>
        <th>ID transaction</th>
        <th>ID the user</th>
        <th>Time</th>
        <th>Status</th>
        <th>The amount</th>
        <th>Selected tariff</th>
        <th>Promo code</th>
        <th>Discount</th>
      </tr>
      {tableRows}
      </table>
    )
  }

  return (
    <>
      <PanelHeader title="Transaction"/>
      
      <SetContent process={process} component={renderItems(transactionList)}/>
      
      <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />
    </>
  )
}

export default Transaction;