import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import Pagination from "../../components/Pagination/Pagination";
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import BtnPrint from "../../components/BtnPrint/BtnPrint";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";

import "./_kitchen.scss";
const Kitchen = () => {

  const [showAlert, setShowAlert] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [kitchenList, setKitchenList] = useState([]);

  const { request, process, setProcess, test } = useHttp();

  const allergensByStr = useSelector((state) => state.matrix.allergens);
  const workdays = useSelector((state) => state.workdays);

  useEffect(() => {
    getKitchenList(workdays[activeTab], currentPage);
  }, []);

  const getKitchenList = (date, pageNumber) => {
    if (test) {
      setKitchenList([
        { id: "1", added: true, name: "burger", age: "3-6", count: "5", allergens: ["0", "0", "1", "0", "0", "0", "1", "0", "1", "0"] },
        { id: "2", added: true, name: "burger", age: "3-6", count: "5", allergens: ["0", "0", "1", "0", "0", "0", "1", "0", "1", "0"] },
        { id: "3", added: false, name: "burger", age: "3-6", count: "5", allergens: ["0", "0", "1", "0", "0", "0", "1", "0", "1", "0"] },
        { id: "4", added: false, name: "burger", age: "3-6", count: "5", allergens: ["0", "0", "1", "0", "0", "0", "1", "0", "1", "0"] },
      ]);
      setProcess("confirmed");
    } else {
      request(undefined, {
        "command": "",
        "pageNumber": pageNumber,
        "date": date,
      }).then((res) => {
        setKitchenList(res.message);
        setTotalPages(res.totalPages);
        setCurrentPage(pageNumber);
        setProcess("confirmed");
      });
    }
  };

  const changePage = (step) => {
    getKitchenList(workdays[activeTab], currentPage + step);
  };

  const changeDate = (i) => {
    setActiveTab(i);
    getKitchenList(workdays[i], 1);
  };

  // запрос на сохранение отмеченных блюд
  const onSave = (id, value) => {
    setKitchenList((state) => {
      const newState = structuredClone(state);
      return newState.map((item) => {
        if (item.id === id) {
          return { ...item, added: !item.added };
        }
        return item;
      });
    });

    if (test) {
      setShowAlert(true);
    } else {
      request(undefined, {
        command: "",
        id: id,
        added: value,
      }).then((res) => {
        // if (res.status === '1') {
        // }
        // setKitchenList(res.kitchenList);
        // setTotalPages(res.totalPages)
        // setShowAlert(true)
        // setProcess('confirmed');
      });
    }
  };

  //render table rows
  const renderItems = (arr) => {
    const tableRows = arr.map((item) => {
      const { id, added, name, age, count, allergens } = item;
      const transformAllergens = [];
      allergens.forEach((item, i) => {
        if (item === "1") {
          transformAllergens.push(allergensByStr[i]);
        }
      });
      for (let i = transformAllergens.length; i < 7; i++) {
        transformAllergens.push("");
      }
      const renderTd = transformAllergens.map((item) => <td>{item}</td>);

      return (
        <tr key={id} onClick={() => onSave(id, !added)}>
          <td>
            <div className={`status ${added ? "ok" : "disable"}`}></div>
          </td>
          <td>{id}</td>
          <td>{name}</td>
          {renderTd}
          <td>{age}</td>
          <td>{count}</td>
        </tr>
      );
    });
    return (
      <table className="table kitchen__table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Item name</th>
            <th>Deleted ingredients #1</th>
            <th>Deleted ingredients #2</th>
            <th>Deleted ingredients #3</th>
            <th>Deleted ingredients #4</th>
            <th>Deleted ingredients #5</th>
            <th>Deleted ingredients #6</th>
            <th>Deleted ingredients #7</th>
            <th>Age</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  const tabs = workdays.map((item, i) => {
    return (
      <button key={i} className={`tabs__btn ${activeTab === i ? "active" : ""}`} onClick={() => changeDate(i)}>
        {item}
      </button>
    );
  });

  return (
    <>
      <PanelHeader title="Kitchen">
        <BtnPrint />
      </PanelHeader>

      <div className="kitchen__tabs tabs">{tabs}</div>

      <SetContent process={process} component={renderItems(kitchenList)} />

      <div className="kitchen__bottom">
        <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />
      </div>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={"The changes are saved"} />
    </>
  );
};

export default Kitchen;
