import {useState, useCallback} from 'react';

export const useHttp = () => {
    const _url = 'https://admin-tummies.creativecom.org/Tummies/mainCommand.php'
    const [process, setProcess] = useState('waiting');
    const test = false; // удалить при релизе

    const request = useCallback(async (url = _url, body = null, headers = {'Content-Type':'application/json'}) => {

        setProcess('loading');

        if( typeof body === 'object' && body !==null && !(body instanceof FormData)) {
            body = JSON.stringify(body)
        }
        if(body instanceof FormData) {
            headers = {}
        }

        console.log({body, headers});

        try {
            const response = await fetch(url, {
                method: test ? 'GET' : 'POST',
                body,
                headers,
            })

            if (!response.ok) {
                throw new Error(`Could not fetch ${url}, status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch(e) {
            setProcess('error')
            throw e;
        }
    }, []);

    const clearError = useCallback(() => {
        setProcess('loading')
    }, []);

    return {request, clearError, process, setProcess, test};
}