import { createSlice } from '@reduxjs/toolkit';

const initialState = getNextFiveDays();
// get next five workdays
function getNextFiveDays() {
  let currentDate = new Date();
  let nextFiveDays = [];

  let daysAdded = 0;
  let i = 0
  while (daysAdded < 5) {
    let nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + i);
    if (!isWeekend(nextDate)) {
      nextFiveDays.push(formatData(nextDate));
      daysAdded++;
    }
    i++
  }

  return nextFiveDays
}

function isWeekend(day) {
  return day.getDay() === 5 || day.getDay() === 6; // 5 - пятница, 6 - суббота
}

function formatData(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

export const workdaysSlice = createSlice({
  name: 'workdays',
  initialState,
  reducers: {
  }
})

// export const { setAllergens, setActivities } = workdaysSlice.actions;

export default workdaysSlice.reducer;