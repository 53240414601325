import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate} from 'react-router-dom';
import DatePicker from "react-datepicker";
import PanelHeader from '../../components/panelHeader/PanelHeader';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { useHttp } from '../../hooks/http.hook';
import "react-datepicker/dist/react-datepicker.css";
import './_editChild.scss'
const EditChild = () =>  {
  const form = useRef(0);
  const {id} = useParams(); // тут будем получать id ребенка, и по нему будем отправлять запрос
  const navigate = useNavigate();
  const allergens = useSelector((state => state.matrix.allergens));
  const activities = useSelector((state => state.matrix.activities));
 
  const {request, process, setProcess, test} = useHttp();

  const [date, setDate] = useState('2024-01-01');
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [targetConfirm, setTargetConfirm] = useState({
      id: null,
      name: null,
      nickname: null
  });

  const [child, setChild] = useState({
    id: '',
    avatar: '',
    name: '',
    nickname: '',
    dateBirth: '',
    gender: '',
    years: '',
    age: '',
    weight: '',
    height: '',
    address: '',
    deliveryTime: '',
    packagePlan: '',
    allergens: [],
    activities: [],
    days: []
  }); // параметры блюда, 



  //отправляем запрос получния данных о блюде
  useEffect(() => { 
    if (test) {
      
      setChild({
        id: '',
        avatar: '',
        name: '',
        nickname: '',
        dateBirth: '',
        gender: '',
        years: '',
        age: '',
        weight: '',
        height: '',
        address: '',
        deliveryTime: '1', // 0 - mornig, 1 - the night before
        packagePlan: '1', // 0 - a week, 1 - month
        allergens: [],
        activities: ["0","1","0","0","1","1"],
        startDate: "2024-06-21",
        endDate:  "2024-06-28",
      })
    } else {
      request(undefined, {
        "command": "",
        "id" : id
      }).then((res)=>{
        setChild(res)
        setProcess('confirmed');
      });
    }
   
  }, []);

  // функция исключения дней
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 5 && day !== 6;
  };

  // функция форматирование даты
  const formatDate = (rawDate) => {
    let date = new Date(rawDate);
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

//обработчик для инпутов с картинками
  const inputFileHandler = (e) => {
    const currFiles = e.target.files;
    if(currFiles.length > 0){
      let src = URL.createObjectURL(currFiles[0])
      setChild(state => {
        const newState = structuredClone(state);
        return {...newState, [e.target.name]: src}
      })
    }
  }

//обработчик текстовых инпутов
  const handleChange = (e) => {
    setChild(state => {
      const newState = structuredClone(state);
      return {...newState, [e.target.name]: e.target.value}
    })
  }
  const handleDate = (date, name) => {
    setChild(state => {
      const newState = structuredClone(state);
      return {...newState, [name]: formatDate(date)}
    })
  }


   // обработчик дней недели и аллергенов
   const handleMatrix = (e, i) => {
    setChild(state => {
      const newState = structuredClone(state);
      let  newMatrix = [...newState[e.target.name]];
      newMatrix[i] =  newMatrix[i] === '1' ? '0' : '1';
      return {...newState, [e.target.name]: newMatrix}
    })
  }

  //отправка формы с запросом на редактирование
  const submitForm = async (e) => {
    e.preventDefault();
    if (test) {
      const body = new FormData(form.current);
      body.delete('allergens');
      body.delete('activities'); // удаляем все чекбоксы из формы
      body.append('allergens', child.allergens) // добавляем матрицу всех чекбоксов аллергенов
      body.append('activities', child.activities) // добавляем матрицу всех чекбоксов активностей

      for (let [key, value] of body) {
        console.log(`${key} — ${value}`)
      }

    } else {
      const body = new FormData(form.current);
      body.delete('allergens');
      body.delete('activities'); // удаляем все чекбоксы из формы
      body.append('allergens', child.allergens) // добавляем матрицу всех чекбоксов аллергенов
      body.append('activities', child.activities) // добавляем матрицу всех чекбоксов активностей
      body.append('command', '')
      request(undefined, body).then((res)=>{
        // получаем код ответа и в зависимости от него формируем текст сообщения
        if (res.responseCode === '1') {
          setShowAlert(true)
          setTextAlert('Child has been successfully saved')
        }
        setProcess('confirmed'); 
        });
    }
  }

  // запрос на удаление ребенка
  const removeChild = (id) => {
    request(undefined, {
        "command": "",
        "id": id
    })
      .then(res => {
          setProcess('confirmed');
          setShowConfirm(false);
          navigate('/children')
      })
  }

  const onConfirmDelete = (id, name, nickname) => {
    setShowConfirm(true);
    setTargetConfirm({id, name, nickname})
  }


  const CustomInput = forwardRef(({inputName, onChange, value, onClick }, ref) => (
    <input type="text" name={inputName} value={value} onChange={onChange} onClick={onClick} ref={ref}/>
  ));

  return (
    <>
      <PanelHeader title="Children" showBackBtn={true}/>
      <form className='child-form' ref={form}>
        <div className='child-form__inner'>
          <div className="child-form__left">
            <label className='child-form__avatar'>
              <input className="sr-only" type="file" name="avatar" accept="image" onChange={inputFileHandler} />
              <img src={child.avatar} alt="" />
              <div className="child-form__plus"></div>
            </label>
          </div>
          <div className="child-form__right">
            <div className="child-form__col-box">
              <div className="child-form__col">
                <label>
                  <span className='child-form__label-name'>id</span>
                  <input type="text" name='id' value={child.id} onChange={handleChange}/>
                </label>
                <label>
                  <span className='child-form__label-name'>Name Surname Middle name</span>
                  <input type="text" name='name' value={child.name} onChange={handleChange}/>
                </label>

                <div className="child-form__labels-box">
                <label>
                  <span className='child-form__label-name'>Years</span>
                  <input type="text" name='years' value={child.years} onChange={handleChange}/>
                </label>
                <label>
                  <span className='child-form__label-name'>Age</span>
                  <input type="text" name='age' value={child.age} onChange={handleChange}/>
                </label>
                <label>
                  <span className='child-form__label-name'>Weight</span>
                  <input type="text" name='weight' value={child.weight} onChange={handleChange}/>
                </label>
                <label>
                  <span className='child-form__label-name'>Height</span>
                  <input type="text" name='height' value={child.height} onChange={handleChange}/>
                </label>
                </div>
              </div>
              <div className="child-form__col">
                <label>
                  <span className='child-form__label-name'>Nickname</span>
                  <input type="text" name='nickname' value={child.nickname} onChange={handleChange}/>
                </label>

                <div className="child-form__labels-box child-form__labels-box--gender">
                  <label>
                    <span className='child-form__label-name'>Date of birth</span>
                    <input type="text" name='dateBirth' value={child.dateBirth} onChange={handleChange}/>
                  </label>
                  <label className="checkbox">
                      <input className="sr-only"
                      type="radio"
                      name="gender"
                      value='1'
                      checked={child.gender === "1"}
                      onChange={handleChange}/>
                      <span>male</span>
                  </label>
                  <label className="checkbox">
                      <input className="sr-only"
                      type="radio"
                      name="gender"
                      value='0'
                      checked={child.gender === "0"}
                      onChange={handleChange}/>
                      <span>female</span>
                  </label>
                </div>
                
                <label>
                  <span className='child-form__label-name'>Address</span>
                  <input type="text" name='address' value={child.address} onChange={handleChange}/>
                </label>
               
              </div>
            </div>

            <div className="child-form__checkboxes">
              <span className='child-form__label-name'>What do you not eat?</span>
                <div className="child-form__checkbox-list">
                  {
                    allergens.map((item, i) => {

                      return (
                        <label className="checkbox-sm" key={i}>
                          <input className="sr-only"
                          type="checkbox"
                          name="allergens"
                          value={item}
                          checked={child.allergens[i] === "1"}
                            onChange={(e) => handleMatrix(e, i)} />
                          <div></div>
                          <span>{item}</span>
                      </label>
                      )
                    })
                  }
              </div>
            </div>

            <div className="child-form__checkboxes">
              <span className='child-form__label-name'>What is your activities?</span>
                <div className="child-form__checkbox-list">
                {
                    activities.map((item, i) => {

                      return (
                        <label className="checkbox-sm" key={i}>
                          <input className="sr-only"
                          type="checkbox"
                          name="activities"
                          value={item}
                          checked={child.activities[i] === "1"}
                            onChange={(e) => handleMatrix(e, i)} />
                          <div></div>
                          <span>{item}</span>
                      </label>
                      )
                    })
                  }
              </div>
            </div>

            <div className="child-form__checkboxes">
              <span className='child-form__label-name'>Delivery time</span>
              <div className="child-form__checkbox-list child-form__checkbox-list--two-col">
                <label className='checkbox-sm'>
                  <input className="sr-only"
                    type="radio"
                    name="deliveryTime"
                    value="0"
                    checked={child.deliveryTime === "0"}
                    onChange={handleChange}/>
                  <div></div>
                  <span>7 AM to 11 AM (Morning)</span>
                </label>
                <label className='checkbox-sm'>
                  <input className="sr-only"
                    type="radio"
                    name="deliveryTime"
                    value="1"
                    checked={child.deliveryTime === "1"}
                    onChange={handleChange}/>
                  <div></div>
                  <span>6 PM to 10 PM (the night before)</span>
                </label>
              </div>
            </div>

            <div className="child-form__checkboxes">
              <span className="child-form__label-name">Package plan</span>
              <div className="child-form__checkbox-list child-form__checkbox-list--two-col">
                <label className="checkbox-sm">
                  <input className="sr-only"
                    type="radio"
                    name="packagePlan"
                    value="0"
                    checked={child.packagePlan === "0"}
                    onChange={handleChange}/>
                  <div></div>
                  <span>A week</span>
                </label>
                <label className="checkbox-sm">
                  <input className="sr-only"
                    type="radio"
                    name="packagePlan"
                    value="1"
                    checked={child.packagePlan === "1"}
                    onChange={handleChange}/>
                  <div></div>
                  <span>Month</span>
                </label>
              </div>
            </div>

            <div className="child-form__date">
              <DatePicker
                selected={child.startDate}
                onChange={(date) => handleDate(date, 'startDate')}
                filterDate={isWeekday}
                placeholderText="Select a weekday"
                customInput={<CustomInput inputName="startDate" />}
              />
              <DatePicker
                selected={child.endDate}
                onChange={(date) => handleDate(date, 'endDate')}
                filterDate={isWeekday}
                placeholderText="Select a weekday"
                customInput={<CustomInput inputName="endDate" />}
              />
          
            </div>

          </div>
        </div>
        <div className="child-form__btns">
          <button className="button button--red" type="button" onClick={() => onConfirmDelete(child.id, child.name, child.nickname)}>Delete</button>
          <button className="button" onClick={submitForm}>Save</button>
        </div>
      </form>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert} />
      <ConfirmModal question='Delete child?' text1={targetConfirm.name} text2={targetConfirm.nickname} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => removeChild(targetConfirm.id)}/>
    </>
  )
}

export default EditChild;