import React, {useEffect, useState, useRef} from "react";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import Pagination from "../../components/Pagination/Pagination";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";

import "./_orderList.scss";
import attention from "./../../resourses/icons/attention.svg"

const OrderList = () => {

  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1)
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState('')
  const [totalPages, setTotalPages] = useState(null); // !!get this value from server side

  const {request, process, setProcess, test} = useHttp();

  const workdays = useSelector((state) => state.workdays);


  useEffect( () => {
    getOrderList(workdays[activeTab], currentPage);
  }, []);

   // get order list
  const getOrderList = (date, pageNumber) => {
    if (test) {
      setOrderList([
        {id: 1, name: 'Name Surname', containAllergen: true, transaction: '1415s1v65s16v15sv', status: '1'},
        {id: 2, name: 'Name Surname', containAllergen: true, transaction: '1415s1v65s16v15sv', status: '0'},
        {id: 3, name: 'Name Surname', containAllergen: false, transaction: '1415s1v65s16v15sv', status: '0'}
      ])
      setProcess('confirmed');
    } else {
      request(undefined, {
        "command": "",
        "pageNumber": pageNumber,
        "date": date
      }).then((res) => {
        setOrderList(res.message); 
        setTotalPages(res.totalPages);
        setCurrentPage(pageNumber);
        setProcess('confirmed');
      });
    }
  }
  
  const changePage = (step) => {
    // step  -1 means previous page and +1 is next
    getOrderList(workdays[activeTab], currentPage + step)
  }

  const changeDate = (i) => {
    setActiveTab(i);
    getOrderList(workdays[i], 1);
  };

   // поиск
   const onSearch = (e) => {
    setSearch(e.target.value)
    request(undefined, {
      "command": "", 
      "date": workdays[activeTab],
      "search": e.target.value
    }).then((res) => {
        setOrderList(res.message); 
        setTotalPages(res.totalPages);
        setCurrentPage(1)
        setProcess('confirmed');
    });
  }

  //render table rows
  const renderItems = (arr) => {
    const orderList = arr.map((item, i) => {
      const {id, name, containAllergen, transaction, status} = item;
      return (
       <li key={id}>
        <Link to={`/order/${id}`} className="list__item orders__item">
          <div className="list__id">{id}</div>
          <div className="list__col-2">{name}</div>
          <div className={`orders__attention ${containAllergen ? "show" : ""}`}
            data-tooltip="Contains an allergen">
            <img src={attention} alt="" />
            </div>
          <div className="list__col-3">{transaction}</div>
          <div className={`status ${status === '1' ? 'ok' : ''}`}></div>
        </Link>
       </li>
      )
    })
    return (
      <ul className="orders list">
        {orderList}
      </ul>
    )
  }

  const tabs = workdays.map((item, i) => {
    return (
      <button key={i} className={`tabs__btn ${activeTab === i ? "active" : ""}`} onClick={() => changeDate(i)}>
        {item}
      </button>
    );
  });


  return (
    <>
      <PanelHeader title="List of orders">
        <div className="search">
          <input type="text" placeholder="Search" value={search} onChange={(e) => onSearch(e)}/>
        </div>
      </PanelHeader>

      <div className="orders__tabs tabs">
        {tabs}
      </div>
      
      <SetContent process={process} component={renderItems(orderList)}/>
      
      <div className="order__bottom">
        <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />
      </div>
      
    </>
  )

}

export default OrderList;